import React from "react";

import Navbar from "react-bootstrap/Navbar";
import { useStaticQuery, graphql } from "gatsby";
import AmpSidebar from "../components/AmpSidebar";
import AmpAnalytics from "../components/AmpAnalytics";
import { useLocation } from "@reach/router";

const HeaderAmp = ({
  translations,
  homepage,
  menu,
  logo,
  slug,
  postTitle,
}) => {
  // get all Flag Icons URL's
  const data = useStaticQuery(graphql`
    query HeaderAmpQuery {
      ...allFlags
      defaultLogo: file(
        relativeDirectory: { eq: "logos" }
        name: { eq: "en" }
      ) {
        childImageSharp {
          fixed(height: 35) {
            src
            srcSet
          }
        }
      }
      site {
        siteMetadata {
          defaultTitle: title
          siteUrl
          defaultImage: image
        }
      }
    }
  `);

  const { pathname } = useLocation();

  const { siteUrl } = data.site.siteMetadata;
  const logoImage = logo && logo.node.childImageSharp.fixed;
  const defaultLogo = data.defaultLogo.childImageSharp.fixed;

  return (
    <>
      <header className="page-header page-header-amp">
        <Navbar expand="lg">
          <Navbar.Brand>
            <a href={homepage} className="d-flex">
              {logoImage ? (
                <img
                  src={logoImage.src}
                  alt="Amazon Associates Logo"
                  width={233}
                  height={35}
                  layout="fixed"
                />
              ) : (
                <img
                  src={defaultLogo.src}
                  alt="Amazon Associates Logo"
                  width={233}
                  height={35}
                  layout="fixed"
                />
              )}
            </a>
          </Navbar.Brand>
          <div role="button" on="tap:sidebar.toggle" tabIndex="0" className="hamburger">
            <span className="icon-burger" />
          </div>
        </Navbar>
      </header>

      <AmpSidebar menu={menu} translations={translations} data={data} />
      <AmpAnalytics pageTitle={postTitle} path={`${siteUrl}${pathname}`} />
    </>
  );
};

export default HeaderAmp;
