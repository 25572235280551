import React from 'react';
import {findFlagBySlug} from "../helper/graphql";

const AmpSidebar = ({ menu, translations, data }) => {
  return (
    <>
      <amp-sidebar id="sidebar" layout="nodisplay" side="left">
        <div role="button" aria-label="close sidebar" on="tap:sidebar.toggle" tabIndex="0" className="close-sidebar">✕</div>
        <div className="nested-menu">
          <amp-nested-menu layout="fill">
            <ul>
              {menu.map(item => {
                if (item.child_items) {
                  // is dropdown
                  return (
                    <li key={item.title}>
                      <h4 amp-nested-submenu-open="">
                        <span dangerouslySetInnerHTML={{ __html: item.title}} /> <span className="icon-arrow-right" style={{ marginLeft: "8px"}} />
                      </h4>
                      <div amp-nested-submenu="">
                        <ul>
                          <li>
                            <h4 amp-nested-submenu-close="" style={{ paddingLeft: 0 }}>
                              <span className="icon-arrow-left" style={{ marginRight: "8px"}} /> Back
                            </h4>
                          </li>
                          {item.child_items.map(childItem => (
                            <li key={childItem.title}>
                              <h4><a href={childItem.url}>{childItem.title}</a></h4>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                  );
                } else {
                  return (
                    <li key={item.url}>
                      <a href={item.url}>
                        <span dangerouslySetInnerHTML={{ __html: item.title}} />
                      </a>
                    </li>
                  );
                }
              })}
              {translations && translations.length > 0 && (
                <li>
                  <h4 amp-nested-submenu-open="">
                    Languages <span className="icon-arrow-right" style={{ marginLeft: "8px"}} />
                  </h4>
                  <div amp-nested-submenu="">
                    <ul>
                      <li>
                        <h4 amp-nested-submenu-close="" style={{ paddingLeft: 0 }}>
                          <span className="icon-arrow-left" style={{ marginRight: "8px"}} /> Back
                        </h4>
                      </li>
                      {translations.map(translation => (
                        <li className="translation-submenu-item" key={translation.locale}>
                          <amp-img
                            src={findFlagBySlug(
                              translation.locale,
                              data.allFile.edges
                            )}
                            alt={translation.locale}
                            style={{ width: 28 }}
                            layout="fixed"
                            width={28}
                            height={18}
                          />
                          <div>
                            <a href={translation.href}>
                            <span className="text-capitalize">
                              {translation.locale_native_name}
                            </span>{" "}
                              -{" "}
                              <span className="text-uppercase">
                              {translation.locale_short.split('-')[0]}
                            </span>
                            </a>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              )}
            </ul>
          </amp-nested-menu>
        </div>
      </amp-sidebar>
    </>
  );
};

export default AmpSidebar;
