import React from "react";

import HeaderAmp from "./HeaderAmp";
import Footer from "./Footer";

import Container from "react-bootstrap/Container";
import SEO from "../components/Seo";
import Breadcrumb from "../components/Breadcrumb";
import AmpConsent from "../components/AmpConsent";

/**
 * Amp Layout Component. Uses page Context to display Header, Main and Footer Components.
 * Implements Breadcrumb logic.
 */
const Layout = props => {
  const translations = props.context.translations;
  const slug = props.context.slug;
  const currentLanguage = props.context.currentLanguage;
  const breadcrumbs = props.context.breadcrumbs;
  const homepagePath = props.context.langHomepagePath;
  const mainClassName = props.className;
  const menus = props.context.menus;
  const logo = props.context.logo;
  const footerLogo = props.context.footerLogo;
  const signUpLink = props.context.signUpLink;
  const stringTranslations = props.context.stringTranslations;
  const pagePath = props.context.pagePath;
  const location = props.location;
  const postTitle = props.postTitle;
  const cookieTranslations = props.context.cookieTranslations;

  return (
    <>
      <SEO {...props} isAmp={true} />
      <HeaderAmp
        translations={translations}
        slug={slug}
        postTitle={postTitle}
        homepage={homepagePath}
        language={currentLanguage}
        logo={logo}
        menu={menus.headerMenu && menus.headerMenu.node.items}
        pagePath={pagePath}
        location={location}
      />
      <main className={mainClassName}>
        <Breadcrumb crumbs={breadcrumbs} />
        <Container>{props.children}</Container>
      </main>

      <AmpConsent cookieTranslations={cookieTranslations} />
      <Footer menu={menus.footerMenu} signUpLink={signUpLink} logo={footerLogo} stringTranslations={stringTranslations} />
    </>
  );
};

Layout.defaultProps = {
  useContainer: true
};

export default Layout;
