import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import { normalizeLanguageCodes } from "../helper/util";

/**
 * Creates all meta data used in the head.
 * You might think you could replace these redundant child Helmet tags to React fragments but you would be wrong: https://github.com/nfl/react-helmet/issues/342#issue-283597275
 * Furthermore Helmet Tags cannot be nested inside each other which results in having Helmet be put around every if-block that we have 😒
 *
 * @param props - All page Context
 */
const SEO = ({ context, description, image, article, isAmp, disableHrefLang = false }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const { currentLanguage, title, yoast, translations } = context;
  const { defaultTitle, siteUrl, defaultImage } = site.siteMetadata;

  const seo = {
    title:
      (yoast && yoast.title) || `${title} - Amazon Associates` || defaultTitle,
    description: (yoast && yoast.description) || description,
    canonical: (!isAmp && yoast && yoast.canonical) || null,
    keyword: (yoast && yoast.keyword) || null,
    robots: {
      adv: (yoast && yoast.robots_adv) || null,
      nofollow: (yoast && yoast.robots_nofollow) || null,
      noindex: (yoast && yoast.robots_noindex) || null
    },
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    facebook: {
      description: (yoast && yoast.facebook_description) || null,
      image: (yoast && yoast.facebook_image) || null,
      title: (yoast && yoast.facebook_title) || null,
      type: (yoast && yoast.facebook_type) || 'article'
    },
    twitter: {
      description: (yoast && yoast.twitter_description) || null,
      image: (yoast && yoast.twitter_image) || null,
      title: (yoast && yoast.twitter_title) || null
    }
  };

  const defaultLanguageTranslation = determineDefaultTranslation(currentLanguage, translations, seo, siteUrl)

  return (
    <>
      <Helmet>
        <html lang={normalizeLanguageCodes(currentLanguage.language_code)} />
        {!isAmp && <meta charSet="utf-8" />}

        <title>{seo.title}</title>
        <meta property="og:title" content={seo.facebook.title || seo.title} />
        <meta name="twitter:title" content={seo.twitter.title || seo.title} />

        <link rel="preload" href="/fonts/Ember/Amazon-Ember-Medium.ttf" as="font" type="font/ttf" crossOrigin="" />
        <link rel="preload" href="/fonts/Ember Condensed/AmazonEmberCdRC_Th.ttf" as="font" type="font/ttf" crossOrigin="" />
        <link rel="preload" href="/fonts/Bookerly Display/BookerlyDisplay_It.ttf" as="font" type="font/ttf" crossOrigin="" />
        <link rel="preload" href="/fonts/Bookerly/Bookerly-Regular.ttf" as="font" type="font/ttf" crossOrigin="" />
      </Helmet>

      {seo.description && (
        <Helmet>
          <meta name="description" content={seo.description} />
          <meta
            property="og:description"
            content={seo.facebook.description || seo.description}
          />
          <meta
            name="twitter:description"
            content={seo.twitter.description || seo.description}
          />
        </Helmet>
      )}

      {seo.keyword && (
        <Helmet>
          <meta name="keywords" content={seo.keyword} />
        </Helmet>
      )}

      {seo.canonical && (
        <Helmet>
          <link rel="canonical" href={seo.canonical} />
        </Helmet>
      )}

      {!disableHrefLang && !seo.robots.noindex && translations && translations.length > 0 && (
        <Helmet>
          <link
            rel="alternate"
            hrefLang={currentLanguage.locale.replace("_", "-")}
            href={seo.url}
          />
          {defaultLanguageTranslation?.href && (
            <link
              rel="alternate"
              hrefLang="x-default"
              href={defaultLanguageTranslation.href}
            />
          )}

          {translations.filter(translation => !translation.no_index).map(translation => {
            return (
              <link
                key={translation.locale}
                rel="alternate"
                hrefLang={translation.locale.replace("_", "-")}
                href={`${siteUrl}${translation.href}`}
              />
            )
          })}
        </Helmet>
      )}

      {seo.image && (
        <Helmet>
          <meta name="image" content={seo.image} />
          <meta property="og:image" content={seo.facebook.image || seo.image} />
          <meta name="twitter:image" content={seo.twitter.image || seo.image} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      )}

      {seo.robots.noindex && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}

      {seo.url && (
        <Helmet>
          <meta property="og:url" content={seo.url} />
        </Helmet>
      )}

      {seo.facebook.type && (
        <Helmet>
          <meta property="og:type" content={seo.facebook.type} />
        </Helmet>
      )}
    </>
  );
};

/**
 * Handles fallback logic for x-default tag
 * Order should be: EN -> DE -> ES -> FR -> IT -> PL -> NL -> CS -> TR -> SV
 * @param currentLanguage Language of current page
 * @param translations All translations of current page
 * @param seo Seo object containing robots noindex and current page full url
 * @return {null|{ href: string }}
 */
const determineDefaultTranslation = (currentLanguage, translations, seo, siteUrl) => {
  if (translations && translations.length > 0) {
    const defaultLanguageOrder = ["en_GB", "de_DE", "es_ES", "fr_FR", "it_IT", "pl_PL", "nl_NL", "cs_CZ", "tr_TR", "sv_SE"]

    for (let i = 0; i < defaultLanguageOrder.length; i++) {
      if (currentLanguage.locale === defaultLanguageOrder[i] && !seo.robots.noindex) return { href: seo.url };

      let defaultLanguage = translations.find(translation => translation.locale === defaultLanguageOrder[i]);
      if (defaultLanguage && !defaultLanguage.no_index) return { href: siteUrl + defaultLanguage.href }
    }

    return null;
  }

  return null;
}

SEO.propTypes = {
  context: PropTypes.shape({
    title: PropTypes.string
  }),
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.object,
  isAmp: PropTypes.bool,
};

SEO.defaultProps = {
  isAmp: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        siteUrl
        defaultImage: image
      }
    }
  }
`;

export default SEO;
