import React from "react";
import PropTypes from "prop-types";
import CenteredImage from "./CenteredImage";

/**
 * Author Component for posts/articles etc.
 * Has 2 variants. Small can be used anywhere, big should be used at the end of a post
 * since it stretches 100% of the parent.
 * Does not display image when acf field is not set for author.
 *
 * @param variant - Either 'small' or 'big'. Defaults to 'small'.
 * @param name - Name of the Author
 * @param image - Image source URL (string)
 * @param children - Additional info from the author or post info (last updated etc.)
 * @param className - Additional Classes for the container
 */
const Author = ({ variant, name, image, imageSrcSet, children, className, by }) => {
  const big = () => (
    <div className={`author author--big ${className}`}>
      {(image || imageSrcSet) && (
        <div className="author__picture">
          <CenteredImage alt={name} source={image} sourceSet={imageSrcSet} isPortrait isRound height={70} width={70} />
        </div>
      )}
      <div className="author__text">
        <div className="author__name">{by} {name}</div>
        {children && (
          <div className="author__info">
            <p>{children}</p>
          </div>
        )}
      </div>
    </div>
  );

  const small = () => (
    <div className={`author author--small ${className}`}>
      {(image || imageSrcSet) && (
        <div className="author__picture">
          <CenteredImage alt={name} isPortrait source={image} sourceSet={imageSrcSet} isRound height={48} width={48} />
        </div>
      )}
      <div className="author__text">
        <div className="author__name">{name}</div>
        <div className="author__info">{children}</div>
      </div>
    </div>
  );

  return variant === "big" ? big() : small();
};

Author.propTypes = {
  variant: PropTypes.oneOf(["big", "small"]),
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  className: PropTypes.string
};

Author.defaultProps = {
  variant: "small",
  by: "by"
};

export default Author;
