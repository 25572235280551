import React  from "react";
import { graphql } from "gatsby";
import LayoutAmp from '../layouts/LayoutAmp';
import Container from "react-bootstrap/Container";
import SocialShare from "../components/SocialShare";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Author from "../components/Author";
import CenteredImage from "../components/CenteredImage";
import '../../styles/amp/main.scss';

/**
 * Post Template used for amp posts.
 * @param pageContext
 * @param data
 */
const PostAmp = ({ pageContext, data, location }) => {
  const post = data.post;
  const authorImage =
    post?.post_author?.acf?.image?.localFile?.childImageSharp?.fluid;
  const stringTranslations = pageContext.stringTranslations;
  const content = post.post_content.replace(/loading="lazy"/g, '').replace(/type="text\/html"/g, '').replace(/align=".*"/g, '').replace(/border=".*"/g, '').replace(/frame/g, '').replace(/<i (.*)>(.*?)<\/i>/g, '');

  return (
    <LayoutAmp
      context={pageContext}
      className="post-page"
      location={location}
      postTitle={post.post_title}
    >
      <article className="article">
        <Container>
          <header>
            <Row className="justify-content-center">
              <Col lg={5} className="d-flex flex-column justify-content-end">
                {post.post_categories && post.post_categories.length > 0 && (
                  <p className="roofline" dangerouslySetInnerHTML={{__html: post.post_categories[0].name}} />
                )}
                <h1 dangerouslySetInnerHTML={{__html: post.post_title}} />
                {post.acf.excerpt && (
                  <div
                    className="intro"
                    dangerouslySetInnerHTML={{ __html: post.acf.excerpt }}
                  />
                )}

                <Author image={authorImage?.src} imageSrcSet={authorImage?.srcSet} name={post.post_author.name}>
                  {stringTranslations["Last updated"]} {post.post_date} • {post.time_to_read.minutes}{" "} {stringTranslations["Min Read"]}
                </Author>
              </Col>
              {post.featured_media && (
                <Col lg={5} className="post-page__feat-image">
                  <CenteredImage
                    alt={post.post_title}
                    source={
                      post.featured_media?.localFile?.childImageSharp?.fluid
                        ?.src
                    }
                    sourceSet={
                      post.featured_media?.localFile?.childImageSharp?.fluid
                        ?.srcSet
                    }
                  />
                </Col>
              )}
            </Row>
          </header>

          <Row>
            <Col lg={{ span: 6, offset: 1 }}>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </Col>
          </Row>

          <div className="post-footer">
            <Row>
              <Col lg={{ span: 6, offset: 1 }}>
                <SocialShare
                  likeCount={
                    Math.round(Math.random() * 1000) /* TODO fix random likes */
                  }
                  path="bla"
                  postId={pageContext.id}
                  postTitle={post.post_title}
                />
              </Col>
            </Row>

            { post.post_author && (
              <Row>
                <Col lg={{ span: 6, offset: 1 }}>
                  <Author
                    image={authorImage?.src}
                    imageSrcSet={authorImage?.srcSet}
                    name={post?.post_author?.name}
                    variant="big"
                    by={stringTranslations["by"]}
                  >
                    {post?.post_author?.description}
                  </Author>
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </article>
    </LayoutAmp>
  );
};

export const query = graphql`
    query AmpPostQuery($id: Int!, $languageCode: String!) {
        site: site {
            siteMetadata {
                siteUrl
            }
        },
        post: wordpressPost(
            wordpress_id: { eq: $id }
            language: { language_code: { eq: $languageCode } }
        ) {
            wordpress_id
            slug
            post_title
            post_content
            acf {
                related_posts {
                    post_title
                    path
                    category {
                        name
                    }
                    image {
                        url {
                            localFile {
                                childImageSharp {
                                    fixed(
                                        width: 100
                                        quality: 100
                                        height: 100
                                        cropFocus: CENTER
                                        fit: COVER
                                    ) {
                                        srcSet
                                        src
                                    }
                                }
                            }
                        }
                    }
                }
                excerpt
            }
            path
            post_date(locale: $languageCode, formatString: "MMMM D, YYYY")
            post_author {
                description
                name
                wordpress_id
                acf {
                    image {
                        localFile {
                            childImageSharp {
                                fluid(jpegQuality: 90, maxWidth: 980) {
                                    srcSet
                                    src
                                }
                            }
                        }
                    }
                }
            }
            time_to_read {
                word_count
                minutes
            }
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(jpegQuality: 100, maxWidth: 980) {
                            srcSet
                            src
                        }
                    }
                }
            }
            post_categories {
                path
                name
                term_id
            }
            language {
                locale
                language_code
                root_path
                locale_native_name
            }
            translations {
                locale
                locale_native_name
                locale_short
                href
            }
        }
    }
`;

export default PostAmp;
