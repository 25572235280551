import React from 'react';

const AmpAnalytics = ({ pageTitle, path }) => {

  const IS_DEV = process.env.GATSBY_IS_DEV || false;

  const pageView = () => {
    const siteID = IS_DEV ? 3 : 2;

    const pageViewURL = `https://t.amazon-affiliate.eu/piwik.php?idsite=${siteID}&rec=1&rand=${Math.random()}&action_name=${encodeURIComponent(pageTitle)}&url=${path}`;

    return `{
      "requests": {
        "pageview": "${pageViewURL}"
      },
      "triggers": {
        "trackPageview": {
          "on": "visible",
          "request": "pageview"
        }
      }
    }`
  };

  return (
    <amp-analytics data-block-on-consent="">
      <script type="application/json" dangerouslySetInnerHTML={{ __html: pageView()}} />
    </amp-analytics>
  );
};

export default AmpAnalytics;
