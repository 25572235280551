import React from 'react';
import CookieConsent from './CookieConsent';

const AmpConsent = ({ cookieTranslations }) => {
  const consentConfig = JSON.stringify({
    "consentInstanceId": "my-consent",
    "promptUI": "consent-ui",
    "consentRequired": true,
  });

  return (
    <amp-consent layout="nodisplay" id="consent-element">
      <script type="application/json" dangerouslySetInnerHTML={{ __html: consentConfig }} />

      <CookieConsent
        isAmp 
        acceptBtnText={cookieTranslations.accept}
        declineBtnText={cookieTranslations.deny}
        infoLink={cookieTranslations.infoLink}
        moreText={cookieTranslations.moreInfo}
      >
        {cookieTranslations?.body}
      </CookieConsent>
    </amp-consent>
  )
}

export default AmpConsent;
