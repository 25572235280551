import React from "react";
import { Link } from "gatsby";

/**
 * Creates a breadcrumb from crumbs array
 * @param crumbs - Array of objects containing { name: string, url: string }
 */
const Breadcrumb = ({ crumbs }) => {
  if (!crumbs || crumbs.length === 0) return null;

  const backCrumb = crumbs.length >= 2 ? crumbs[crumbs.length - 2] : null;

  return (
    <div className="breadcrumb">
      <ul className="breadcrumb__list">
        {crumbs.map((crumb, index) => (
          <React.Fragment key={crumb.name}>
            <li className="breadcrumb__list__item">
              {crumb.url ? (
                <Link to={crumb.url}>
                  <span dangerouslySetInnerHTML={{__html: crumb.name}} />
                </Link>
              ) : (
                <span className="disabled" dangerouslySetInnerHTML={{__html: crumb.name}} />
              )}
            </li>
            {index !== crumbs.length - 1 && (
              <li>
                <span className="breadcrumb__separator">›</span>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
      {backCrumb && (
        <div className="breadcrumb__back">
          <Link to={backCrumb.url}>‹&nbsp;&nbsp;&nbsp;Back</Link>
        </div>
      )}
    </div>
  );
};

export default Breadcrumb;
