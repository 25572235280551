/**
 * Normalizes hreflang's that come from wordpress to the correct html lang equivalent.
 * @param languageCode Current language code
 * @returns {string|*}
 */
export const normalizeLanguageCodes = (languageCode) => {
  return languageCode;
}

/**
 * Restricts a string to a certain max length and adds '...' at the end if the string is too long.
 * @param text The text to be shortened
 * @param count Maximum number of characters
 * @return {string} Shortened string
 */
export const makeExcerpt = (text, count) => {
  return text.slice(0, count) + (text.length > count ? "..." : "");
}
